import styled, { keyframes } from 'styled-components'
import { onNotLarge } from '../../../styles/mediaQueries'
import Marquee from 'react-fast-marquee'

export const Section = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3984f4;
`

export const Keyword = styled.span`
  background-color: white;
  font-size: 24px;
  padding: 12px 20px;
  font-weight: 700;
  margin-bottom: 30px;
  border-radius: 16px;
  color: #3984f4;
  ${onNotLarge} {
    padding: 16ppx 12px;
    font-size: 16px;
    margin-bottom: 20px;
  }
`

export const Title = styled.h2`
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 50px;
  text-align: center;
  color: white;
  ${onNotLarge} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  img:nth-child(1) {
    width: 400px;
  }

  ${onNotLarge} {
    flex-direction: column;
    align-items: center;
    img:nth-child(1) {
      width: 330px;
    }
  }
`

export const ResumeCard = styled.div`
  background-color: white;
  width: 400px;
  height: 402px;
  padding: 28px 0;
  border-radius: 24px;
  ${onNotLarge} {
    width: 330px;
    height: 334px;
    padding: 23px 0;
  }
`

export const CardTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 40px;
  ${onNotLarge} {
    font-size: 19.8px;
    padding-left: 33px;
  }
`

export const CardDesc = styled.div`
  font-size: 20px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  padding-left: 40px;
  margin-bottom: 80px;
  ${onNotLarge} {
    font-size: 16.5px;
    line-height: 21.4px;
    padding-left: 33px;
  }
`

export const ScrollContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100px;
  position: relative;
`

export const Wrapper = styled(Marquee)`
  /* width: auto; */
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px; /* 로고 컨테이너의 너비를 명확히 설정 */
  height: 80px; /* 로고 컨테이너의 높이를 명확히 설정 */
  margin: 0 20px; /* 로고 컨테이너 사이의 간격을 20px로 설정 */
`

export const Logo = styled.img`
  max-height: 100%; /* 로고 이미지의 최대 높이를 컨테이너 높이에 맞춤 */
  max-width: 100%; /* 로고 이미지의 최대 너비를 컨테이너 너비에 맞춤 */
  object-fit: contain; /* 로고 이미지의 비율을 유지하며 컨테이너에 맞춤 */
`
