import styled, { keyframes, css } from 'styled-components'
import IMG1 from '../../../assets/normal.png'
import IMG2 from '../../../assets/fast.png'
import { onNotLarge, onLarge } from '../../../styles/mediaQueries'

const Section = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 70px;
  text-align: center;
  em {
    color: #3984f4;
  }

  ${onNotLarge} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 60px;
  }
`

const ContentWrapeer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  img {
    width: 290px;
  }
  ${onNotLarge} {
    gap: 10px;
    img {
      width: 156px;
    }
  }
`

function FastSection() {
  return (
    <Section>
      <Title>
        누군가는 이미 앞서가고 있어요
        <br />
        <em>널패스를 통해!</em>
      </Title>
      <ContentWrapeer>
        <img src={IMG1} alt="느림" />
        <img src={IMG2} alt="빠름" />
      </ContentWrapeer>
    </Section>
  )
}

export default FastSection
