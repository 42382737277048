import styled, { keyframes, css } from 'styled-components'
import { onNotLarge, onLarge } from '../../../styles/mediaQueries'

const Section = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
`

const Title = styled.div`
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 60px;
  text-align: center;
  color: white;
  ${onNotLarge} {
    font-size: 28px;
    line-height: 36px;
  }
`

const CTA = styled.button`
  border-radius: 100px;
  background: linear-gradient(94deg, #4891ff -7.52%, #7350ff 112.18%);
  padding: 16px 40px;
  color: white;
  font-size: 16px;
  font-weight: 700;
`

function CtaSection() {
  const onClickPayment = () => {
    window.open('https://www.latpeed.com/products/NYFT8', '_blank')
  }

  return (
    <Section>
      <Title>
        지금 바로 시작하고
        <br />
        취업 준비를 앞서가세요!
      </Title>
      <CTA
        onClick={() => {
          onClickPayment()
        }}
      >
        지금 시작하기 →
      </CTA>
    </Section>
  )
}

export default CtaSection
