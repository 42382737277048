import styled from 'styled-components'
import { onNotLarge } from '../../styles/mediaQueries'
import logo from '../../assets/logo_nurpass.svg'
import { useNavigate } from 'react-router-dom'

export const HeaderSection = styled.header`
  background-color: #fff;
  border-bottom: 1px #000;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  ${onNotLarge} {
    padding: 12px 20px;
  }
`

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
`

export const Logo = styled.div`
  align-self: center;

  img {
    width: 150px;
    object-fit: cover;
    ${onNotLarge} {
      width: 94px;
    }
  }
`

export const Login = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #666e7b;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  ${onNotLarge} {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
`

function Header() {
  const navigate = useNavigate()

  const onClickPayment = () => {
    window.open('https://nurpass.im/login_page', '_blank')
  }

  return (
    <HeaderSection>
      <HeaderContainer>
        <Logo>
          <img src={logo} alt="logo" onClick={() => navigate('/')} />
        </Logo>
        <Login onClick={onClickPayment}>로그인</Login>
      </HeaderContainer>
    </HeaderSection>
  )
}

export default Header
