import * as S from './TitleSection.style'
import Nurpat from '../../../assets/chat_nurpat.svg'
import React, { useEffect, useState } from 'react'

const messages = [
  { text: '널패스! 자기소개서 작성하고 싶어!', isUser: true },
  { text: '선생님의 이야기를 알려주시면 선생님만의 자기소개서를 작성해볼게요! 😃', isUser: false },
  { text: '어떤 경험을 하셨는지 알려주시겠어요?', isUser: false },
  { text: '흠.. 어떤 경험이 좋을까? 🤔', isUser: true },
  {
    text: '특별한 경험이 아니어도 괜찮아요! 키워드와 강점을 추출하고 세상에 없는 자기소개서를 써볼게요! 💙',
    isUser: false,
  },
]

export const Chat = () => {
  const [displayedMessages, setDisplayedMessages] = useState<{ text: string; isUser: boolean }[]>(
    []
  )
  const [messageIndex, setMessageIndex] = useState(0)
  const [profileTop, setProfileTop] = useState<number | null>(null)
  const [lastProfileTop, setLastProfileTop] = useState<number | null>(null)

  useEffect(() => {
    if (messageIndex < messages.length) {
      const timer = setTimeout(() => {
        const currentMessage = messages[messageIndex]
        setDisplayedMessages((prevMessages) => [...prevMessages, currentMessage])
        if (!currentMessage.isUser) {
          if (profileTop === null) {
            setProfileTop(messageIndex * 80) // Adjust based on message container height
          } else if (profileTop !== null && messageIndex === 2) {
            setProfileTop(messageIndex * 64.3) // Adjust based on message container height
          } else if (messageIndex === 4) {
            setLastProfileTop(messageIndex * 60) // Adjust based on message container height
          }
        }
        setMessageIndex((prevIndex) => prevIndex + 1)
      }, 1000) // 1초 간격으로 메시지 추가

      return () => clearTimeout(timer) // 클린업
    }
  }, [messageIndex])

  return (
    <S.ChatContainer>
      <S.BotContainer>
        <S.BotIcon>
          <img src={Nurpat} alt="Bot Icon" />
        </S.BotIcon>
        <div>
          <S.BotText>널패스</S.BotText>
          <S.BotSubtitle>간호사 취업을 위한 AI 어시스턴트</S.BotSubtitle>
        </div>
      </S.BotContainer>
      <S.ChatContent>
        {profileTop !== null && (
          <S.ProfileContainer top={profileTop}>
            <img src={Nurpat} alt="Profile Icon" />
          </S.ProfileContainer>
        )}
        {lastProfileTop !== null && (
          <S.ProfileContainer top={lastProfileTop}>
            <img src={Nurpat} alt="Profile Icon" />
          </S.ProfileContainer>
        )}
        {displayedMessages.map((message, index) => (
          <S.MessageContainer key={index} isUser={message.isUser}>
            <S.ChatMessage
              isUser={message.isUser}
              isSameSpeaker={index > 0 && displayedMessages[index - 1]?.isUser === message.isUser}
              delay={index * 0.5} // 애니메이션 딜레이
            >
              {message.text}
            </S.ChatMessage>
          </S.MessageContainer>
        ))}
      </S.ChatContent>
    </S.ChatContainer>
  )
}
