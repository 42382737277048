import * as S from './KeyWord.style'
import keyword1 from '../../../assets/keyword1.png'
import keyword2 from '../../../assets/keyword2.png'

const KeywordSection = () => {
  return (
    <S.Section>
      <S.Keyword>나만의 키워드 추출</S.Keyword>
      <S.Title>
        내 경험을 통해
        <br />
        강점, 키워드를 추출해요
      </S.Title>
      <S.ImgContainer>
        <img src={keyword1} alt="키워드1" />
        <img src={keyword2} alt="키워드2" />
      </S.ImgContainer>
    </S.Section>
  )
}

export default KeywordSection
