import styled, { keyframes, css } from 'styled-components'
import { onNotLarge, onLarge } from '../../../styles/mediaQueries'

export const Section = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    0deg,
    #f3f9ff 0%,
    rgba(242, 246, 255, 0) 100%,
    rgba(242, 246, 255, 0.09) 100%
  );
`

export const Heading = styled.h1`
  font-size: 52px;
  font-weight: 700px;
  line-height: 70px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  em {
    color: #3984f4;
  }

  ${onNotLarge} {
    font-size: 38px;
    line-height: 50px;
  }
`

export const Description = styled.p`
  font-size: 24px;
  line-height: 36px;
  color: #6d6d6d;
  font-weight: 600;
  margin-bottom: 50px;
  ${onNotLarge} {
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`

export const CTA = styled.button`
  font-size: 18px;
  line-height: 20px;
  background: linear-gradient(130deg, #4891ff 0%, #7350ff 100%);
  color: white;
  border: none;
  border-radius: 100px;
  padding: 16px 40px;
  font-weight: 700;
  margin-bottom: 40px;
  ${onLarge} {
    margin-bottom: 50px;
  }
`

export const ChatContainer = styled.div`
  width: 320px;
  height: 384px;
  position: relative;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 0; // 스크롤바를 없애기 위해 너비를 0으로 설정
    background: transparent;
  }
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  ${onLarge} {
    padding: 40px 40px 40px 40px;
    width: 790px;
    height: 484px;
  }
`

export const ChatContent = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
`

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const messageStyles = css<{ isUser: boolean; isSameSpeaker: boolean; delay: number }>`
  padding: 8px 16px;
  background: ${(props) => (props.isUser ? '#3984f4' : '#f5f5fe')};
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  color: ${(props) => (props.isUser ? 'white' : 'black')};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  max-width: 253px;
  margin-left: ${(props) => (props.isUser ? 'auto' : '0')};
  margin-right: ${(props) => (props.isUser ? '0' : 'auto')};
  margin-top: ${(props) => (props.isSameSpeaker ? '8px' : '16px')};
  animation: ${fadeInUp} 0.5s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(20px);
  /* animation-delay: ${(props) => `${props.delay}s`}; */
  ${onLarge} {
    max-width: 400px;
    font-size: 18px;
    line-height: 28px;
    /* width: 790px; */
    /* height: 484px; */
  }
`

export const ChatMessage = styled.div.attrs<{
  isUser: boolean
  isSameSpeaker: boolean
  delay: number
}>(({ isUser, isSameSpeaker, delay }) => ({
  isUser,
  isSameSpeaker,
  delay,
}))<{
  isUser: boolean
  isSameSpeaker: boolean
  delay: number
}>`
  ${messageStyles}
`

export const BotContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  ${onLarge} {
  }
`

export const BotIcon = styled.div`
  width: 40px;
  height: 40px;
  background: #d3e2fe;
  border-radius: 13.33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  ${onLarge} {
    margin-right: 20px;
    img {
      width: 50px;
      height: 50px;
    }
  }
`

export const BotText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 6px;
  ${onLarge} {
    font-size: 20px;
    margin-bottom: 10px;
  }
`

export const BotSubtitle = styled.div`
  color: #8d96a1;
  font-size: 14px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 16px;
  ${onLarge} {
    font-size: 16px;
    line-height: 18px;
  }
`

export const ChatProfile = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
`

export const MessageContainer = styled.div<{ isUser: boolean }>`
  display: flex;
  align-items: flex-start;
  ${(props) => (props.isUser ? 'flex-direction: row-reverse;' : 'flex-direction: row;')}
  gap: 10px;
  padding-left: ${(props) => (props.isUser ? '0' : '36px')}; /* 프로필 영역 확보 */
  ${onLarge} {
    padding-left: ${(props) => (props.isUser ? '0' : '50px')}; /* 프로필 영역 확보 */
  }
`

const ProfilefadeInUp = keyframes<{ top: number }>`
  from {
    opacity: 0;
    transform: translateY(${(props) => props.top}+20px);
  }
  to {
    opacity: 1;
    transform: translateY(${(props) => props.top}px);
  }
`

export const ProfileContainer = styled.div<{ top: number }>`
  position: absolute;
  left: 0;
  border-radius: 13.33px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(${(props) => props.top}px);
  transition: transform 0.5s ease-out;
  animation: ${ProfilefadeInUp} 0.5s ease-out;
  animation-fill-mode: forwards;
  img {
    width: 32px;
    height: 32px;
  }
  ${onLarge} {
    transform: translateY(${(props) => props.top * 1.2}px);
    img {
      width: 40px;
      height: 40px;
    }
  }
`

export const ProfileContainerStatic = styled.div`
  position: absolute;
  left: 0;
  transition: transform 0.5s ease-out;
  border-radius: 13.33px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
`
