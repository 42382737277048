import { KakaoLoginButton } from './components/LoginButton'
import styled from 'styled-components'

const LoginContainer = styled.section`
  padding: 140px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 0 20px; */
`

const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin: 16px 0px 50px;
`

function LoginPage() {
  return (
    <LoginContainer>
      <Title>로그인</Title>
      <KakaoLoginButton />
    </LoginContainer>
  )
}

export default LoginPage
