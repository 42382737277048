import TitleSection from './components/TitleSection'
import HardSection from './components/HardSection'
import Review from './components/Review'
import KeywordSection from './components/KeywordSection'
import ResumeSection from './components/Resume'
import WhyNupass from './components/WhyNurpass'
import FastSection from './components/FastSection'
import FAQ from './components/FAQ'
import CtaSection from './components/CTA'

function LandingPage() {
  return (
    <>
      <TitleSection />
      <HardSection />
      <Review />
      <KeywordSection />
      <ResumeSection />
      <WhyNupass />
      <FastSection />
      <FAQ />
      <CtaSection />
    </>
  )
}

export default LandingPage
