import styled from 'styled-components'
import prepare from '../../../assets/prepare.png'
import { onNotLarge } from '../../../styles/mediaQueries'

const Container = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 150px;
  ${onNotLarge} {
    flex-direction: column;
    gap: 50px;
  }
`

const Title = styled.h2`
  color: #000;
  font-size: 40px;
  font-weight: 800;
  line-height: 140%; /* 56px */
  em {
    color: #3984f4;
  }
  ${onNotLarge} {
    font-size: 28px;
  }
`

const Prepare = styled.div`
  img {
    width: 600px;
    height: 495px;
  }
  ${onNotLarge} {
    img {
      width: 342px;
      height: 293px;
    }
  }
`

function HardSection() {
  return (
    <Container>
      <Title>
        <em>어려워지는 간호사 취업</em>
        <br />
        어떻게 준비하고 있나요?
      </Title>
      <Prepare>
        <img src={prepare} alt="간호사 취업의 어려움" />
      </Prepare>
    </Container>
  )
}

export default HardSection
