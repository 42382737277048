import styled from 'styled-components'
import { onNotLarge } from '../../../styles/mediaQueries'

export const Section = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Keyword = styled.span`
  background-color: #3984f4;
  font-size: 24px;
  padding: 12px 20px;
  font-weight: 600;
  margin-bottom: 30px;
  border-radius: 16px;
  color: white;
  ${onNotLarge} {
    padding: 16ppx 12px;
    font-size: 16px;
    margin-bottom: 20px;
  }
`

export const Title = styled.h2`
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 50px;
  text-align: center;
  ${onNotLarge} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  img {
    width: 400px;
  }
  ${onNotLarge} {
    display: flex;
    flex-direction: column;

    img {
      width: 330px;
    }
  }
`
