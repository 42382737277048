import styled from 'styled-components'
import { onNotLarge } from '../../../styles/mediaQueries'

export const Section = styled.section`
  padding: 100px 20px;
  background: #f6faff;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  color: black;
  font-size: 40px;
  font-family: 'Pretendard';
  font-weight: 700;
  text-align: center;
  margin-bottom: 70px;
  ${onNotLarge} {
    font-size: 24px;
    margin-bottom: 50px;
  }
`

export const FAQWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FAQItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Question = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const QuestionText = styled.div`
  color: black;
  font-size: 20px;
  font-family: 'Pretendard';
  font-weight: 700;
  ${onNotLarge} {
    font-size: 16px;
  }
`

interface ArrowProps {
  isOpen: boolean
}

export const Arrow = styled.div<ArrowProps>`
  width: 12px;
  height: 12px;
  border: solid black;
  border-width: 2px 2px 0 0;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(135deg)' : 'rotate(-45deg)')};
  transition: transform 0.2s;
  ${onNotLarge} {
    width: 10px;
    height: 10px;
  }
`

export const Answer = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-top: 16px;
  white-space: pre-wrap; /* 줄바꿈 인식 */
  color: #9c9c9c;
  padding: 0 10px;
  ${onNotLarge} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background: #222222;
  margin-top: 24px;
`

export const Link = styled.a`
  text-decoration: underline;
  color: black; /* 링크 색상을 기존 텍스트 색상과 동일하게 유지합니다. */
  &:hover {
    color: blue; /* 호버 시 색상을 변경할 수 있습니다. */
  }
`
