import * as S from './Resume.style'
import resume1 from '../../../assets/resume2.png'
import HOS1 from '../../../assets/HOS1.png'
import HOS2 from '../../../assets/HOS2.png'
import HOS3 from '../../../assets/HOS3.png'
import HOS4 from '../../../assets/HOS4.png'
import HOS5 from '../../../assets/HOS5.png'
import HOS6 from '../../../assets/HOS6.png'
import HOS7 from '../../../assets/HOS7.png'

const logos = [HOS1, HOS2, HOS3, HOS4, HOS5, HOS6, HOS7]

function ResumeSection() {
  return (
    <S.Section>
      <S.Keyword>자기소개서 작성/첨삭</S.Keyword>
      <S.Title>
        병원 맞춤형
        <br />
        자기소개서를 만들어요
      </S.Title>
      <S.ImgContainer>
        <img src={resume1} alt="나만의 자기소개서 작성" />
        <S.ResumeCard>
          <S.CardTitle>맞춤형 자기소개서 첨삭</S.CardTitle>
          <S.CardDesc>
            원하는 병원을 선택하면
            <br />
            비전 및 인재상까지 반영해요
          </S.CardDesc>
          <S.ScrollContainer>
            <S.Wrapper gradient={false} speed={100}>
              {logos.map((logo, index) => (
                <S.LogoWrapper>
                  <S.Logo key={index} src={logo} alt={`병원 로고 ${index + 1}`} />
                </S.LogoWrapper>
              ))}
            </S.Wrapper>
          </S.ScrollContainer>
        </S.ResumeCard>
      </S.ImgContainer>
    </S.Section>
  )
}

export default ResumeSection
