import styled from "styled-components";
// import LOGO from "../../assets/LOGO_WHITE.svg";
import INSTA from "../../../assets/SNS_INSTA.svg";
import KAKAO from "../../../assets/SNS_KAKAO.svg";

const StyledFooter = styled.footer`
  background-color: #191f28;
  color: #666;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 40px 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  address {
    font-style: normal; // 이탤릭체 제거
    color: #6b7684;
  }
  p {
    color: #6b7684;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: 390px;
`;

const Logo = styled.div`
  width: 66px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  img {
    cursor: pointer;
    width: 28px;
    height: 28px;
    object-fit: cover;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <FooterWrapper>
        
        <Icon>
          <img
            onClick={() => {
              window.open("https://www.instagram.com/nurpass_nurse/");
            }}
            src={INSTA}
            alt="인스타그램"
          />
          <img
            onClick={() => {
              window.open("https://pf.kakao.com/_EhBxeG");
            }}
            src={KAKAO}
            alt="카카오 채널"
          />
        </Icon>
        <address>
          따끔 | 대표: 김정현
          <br />
          사업자 등록번호: 873-56-00803
          <br />
          경기도 양주시 고암길 306-40 219동 2003호
          <br />
          전화번호: 070-8983-4351
        </address>
        <p>ⓒ 2024 TEAM SPECTRUM corp.</p>
      </FooterWrapper>
    </StyledFooter>
  );
};

export default Footer;
