import './App.css'
import Header from './components/Header'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LandingPage from './views/LandingPage'
import LoginPage from './views/LoginPage'
import KakaoRedirectHandler from './components/KakaoRedirectHandler'
import MetaTag from './components/MetaTag'
import Footer from './views/LandingPage/components/Footer'
import RegistrationForm from './views/SignupPage'

function App() {
  return (
    <>
      {/* <MetaTag /> */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signup" element={<RegistrationForm />} />
          <Route path="/auth/kakao/callback" element={<KakaoRedirectHandler />} />
        </Routes>
        <Footer/>
      </Router>
    </>
  )
}

export default App
