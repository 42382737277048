import useMatchScreenSize from '../../../utils/hooks/useMatchScreenSize'
import { Chat } from './Chat'
import * as S from './TitleSection.style'

function TitleSection() {
  const { isLarge } = useMatchScreenSize()
  const onClickPayment = () => {
    window.open('https://www.latpeed.com/products/NYFT8', '_blank')
  }

  return (
    <S.Section>
      <S.Heading>
        <em>간호사 취업</em>을<br />
        앞서가는 확실한 방법!
      </S.Heading>
      <S.Description>현직 간호사들이 만든 널패스로 준비하세요!</S.Description>
      <S.CTA onClick={onClickPayment}>지금 시작하기 →</S.CTA>
      <Chat />
    </S.Section>
  )
}

export default TitleSection
