import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function KakaoRedirectHandler (){
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      // 서버에 코드 전달
      axios.post('/auth/kakao/callback', { code })
        .then(response => {
          console.log('로그인 성공:', response.data);
          // 로그인 성공 후 처리 로직 (예: 토큰 저장, 사용자 정보 처리 등)
        })
        .catch(error => {
          console.error('로그인 실패:', error);
        });
    }
  }, [location]);

  return (
    <div>
      로그인 처리 중...
    </div>
  );
};

export default KakaoRedirectHandler