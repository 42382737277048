import * as S from './FAQ.style'
import { useState } from 'react'

const QUESTIONS = [
  {
    question: '널패스는 어떤 서비스인가요?',
    answer:
      '간호사 취업에 도움을 주는 AI 서비스입니다. 현직 간호사들이 개발하고 실제 합격자 자기소개서와 면접 답변를 반영했습니다. 현재는 자기소개서 작성 & 첨삭 서비스를 제공하며 추후 면접 준비 서비스도 도입될 예정입니다.',
  },
  {
    question: '기존 GPT랑 어떤 점이 다른가요?',
    answer:
      '널패스AI는 일반 GPT와 다르게 간호사 병원 취업을 위한 AI 서비스이며 나만의 자기소개서를 쓸 수 있도록 맞춤화된 서비스를 제공합니다.',
  },
  {
    question: '다른 사람들과 문장이 겹칠 수도 있나요?',
    answer:
      '내 경험을 바탕으로 자기소개서를 작성하기 때문에 나만의 차별화된 자기소개서가 완성됩니다!',
  },
  {
    question: '언제 어디서든 사용이 가능한가요?',
    answer: '네! 첨삭 의뢰를 위해 따로 기다릴 필요 없이 언제 어디서든 첨삭이 가능합니다!',
  },
  {
    question: 'PC에서만 사용이 가능한가요?',
    answer:
      '모바일에서도 사용이 가능하지만 원활한 서비스 이용을 위해 PC에서 사용하시는 것이 좋습니다!',
  },
  {
    question: '실제 합격한 사람이 있나요?',
    answer: (
      <>
        그럼요!&nbsp;
        <S.Link
          href="https://www.instagram.com/nurpass_nurse"
          target="_blank"
          rel="noopener noreferrer"
        >
          널패스 인스타그램
        </S.Link>
        에 오시면 실제 합격한 분들의 후기도 보실 수 있습니다!
      </>
    ),
  },
]

function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <S.Section>
      <S.Title>자주 묻는 질문</S.Title>
      <S.FAQWrapper>
        {QUESTIONS.map((item, index) => (
          <S.FAQItem key={index}>
            <S.Question onClick={() => handleToggle(index)}>
              <S.QuestionText>{item.question}</S.QuestionText>
              <S.Arrow isOpen={openIndex === index} />
            </S.Question>
            {openIndex === index && <S.Answer>{item.answer}</S.Answer>}
            <S.Divider />
          </S.FAQItem>
        ))}
      </S.FAQWrapper>
    </S.Section>
  )
}

export default FAQ
