import * as S from './WhyNurpass.style'
import Write from '../../../assets/write.png'

const DESCS = [
  {
    title: '기다릴 필요 없이 언제든.',
    subTitle: '첨삭을 기다릴 필요없이\n내가 원할 때, 언제든 가능해요.',
  },
  {
    title: '나에게 딱 맞는 첨삭.',
    subTitle: '내 경험을 기반으로 만들고\n원하는 병원에 맞게 첨삭해요.',
  },
  {
    title: '현직 간호사들이 만든.',
    subTitle: '간호사 취업은 간호사에게!\n간호사 취업은 간호사가 제일 잘 알죠.',
  },
]

function WhyNupass() {
  return (
    <S.Section>
      <S.Keyword>커리어를 앞서가는 확실한 방법!</S.Keyword>
      <S.Title>왜 널패스를 선택해야 할까요?</S.Title>
      <S.ImgContainer>
        <img src={Write} alt="자기소개서" />
      </S.ImgContainer>
      <S.DescWrapper>
        {DESCS.map((desc, index) => (
          <S.Description key={index}>
            <h3>{desc.title}</h3>
            <p>{desc.subTitle}</p>
          </S.Description>
        ))}
      </S.DescWrapper>
    </S.Section>
  )
}

export default WhyNupass
