import styled, { keyframes, css } from 'styled-components'
import { onNotLarge, onLarge } from '../../../styles/mediaQueries'

export const Section = styled.section`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d6e5fd;
`

export const Keyword = styled.span`
  color: #3984f4;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
  ${onNotLarge} {
    font-size: 20px;
    margin-bottom: 10px;
  }
`

export const Title = styled.div`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 80px;
  ${onNotLarge} {
    font-size: 28px;
    margin-bottom: 70px;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  img {
    width: 241px;
  }
`
export const DescWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 110px;
  ${onNotLarge} {
    width: 100%;
    padding-left: 20px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 60px;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: #747474;
    white-space: pre-line;
  }

  ${onNotLarge} {
    width: 100%;
    gap: 12px;
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
