import { useEffect } from 'react'
import styled from 'styled-components'
import kakaoLogo from '../../../assets/logo_kakao.png'

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 52px;
  background-color: #fee500;
  border: none;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    background-color: #ffd700;
  }
`

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

export const KakaoLoginButton = () => {
  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('536846ea89e3d8ec228d819b97c8fbf5') // 여기에 실제 카카오 앱 키를 입력하세요.
    }
  }, [])

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: 'http://localhost:3000/auth/kakao/callback', // 카카오 개발자 콘솔에 설정한 리디렉션 URI
    })
  }

  return (
    <LoginButton onClick={loginWithKakao}>
      <Logo src={kakaoLogo} alt="kakao" />
      카카오로 시작하기
    </LoginButton>
  )
}
