import * as S from './Review.style'

const Card = [
  {
    title: '중앙대학교병원(서울)합격',
    subtitle: '대외활동 경험이 없어서 걱정했는데 널패스로 해결했어요!',
    desc: '대외활동 경험이 없어서 어떤 걸 써야할지 고민했는데 널패스와 몇 번 대화를 하니 자기소개서를 쓸 수 있었어요. 자소서 소재를 만들어주고 요약까지 해주니 금방 완성했어요!',
    footer: ['박*현', 'K대학교 간호학과 4학년'],
  },
  {
    title: '중앙대학교병원(서울)합격',
    subtitle: '자기소개서를 처음 써봤는데 정말 큰 도움이 됐어요.',
    desc: '자기소개서를 처음 작성할때 틀을 잡는데 큰 도움이 됐어요. 첨삭 기능을 이용해서 글의 가독성을 높이고 글자수 조정까지 할 수 있어서 큰 도움이 됐어요! ',
    footer: ['김*지', 'S대학교 간호학과 4학년'],
  },
  {
    title: '중앙대학교병원(광명)합격',
    subtitle: '병원의 미션, 비전까지 반영해주니큰 도움이 됐어요!',
    desc: '공고가 갑자기 떠서 전혀 준비를 못 했었는데, 널패스 덕분에 방향을 잡을 수 있었어요. 병원의 미션이나 비전을 반영하기 어려웠는데 AI가 적절히 첨삭해줘서 큰 도움이 됐습니다. ',
    footer: ['송*비', 'S대학교 간호학과 4학년'],
  },
  {
    title: '중앙대학교병원(광명)합격',
    subtitle: '막막한 자기소개서 소재, 널패스로 해결했어요!',
    desc: '생각해내지 못한 소재들을 널패스를 사용하면서 쉽게 풀 수 있었어요. 스토리텔링 형식으로 이어나가며 자기소개서를 쉽게 완성할 수 있어서 좋았습니다!',
    footer: ['김*진', 'B대학교 간호학과 4학년'],
  },
  {
    title: '중앙대학교병원(광명)합격',
    subtitle: '경험을 기반으로 만들어주니 다른사람들과 문장이 겹칠 걱정 없어요!',
    desc: '‘AI로 자소서를 쓰면 다른 사람들과 문장이 겹치지 않을까?’ 걱정이 있었는데 결과물을 보니 경험에 기반한 문장이어서 다른 사람과 겹칠 수가 없더라구요. 많은 도움 받았습니다!',
    footer: ['정*영', 'G대학교 간호학과 4학년'],
  },
  {
    title: '중앙대학교병원(광명)합격',
    subtitle: '널패스 덕분에 2일 만에 자기소개서를 완성했어요!',
    desc: '2일 만에 자소서를 완성해야하는 상황이라 부담이 컸는데 널패스 덕분에 빨리 끝낼 수 있었어요. 초안을 잡는 것이 제일 어려운 부분인데 널패스의 도움을 많이 받은 것 같아요. ',
    footer: ['김*희', 'K대학교 간호학과 4학년'],
  },
]

function Review() {
  return (
    <S.ReviewSection>
      <S.Emoji>💬</S.Emoji>
      <S.Title>
        합격 후기로 보는 <em>널패스</em>
      </S.Title>
      <S.SubTitle>원하는 병원에 합격한 선생님들의 후기입니다</S.SubTitle>
      <S.CarouselWrapper>
        {Card.map((review, index) => (
          <S.ReviewCard key={index}>
            <S.CardHeader>
              <S.CardTitle>{review.title}</S.CardTitle>
              <S.CardSubtitle>{review.subtitle}</S.CardSubtitle>
            </S.CardHeader>
            <S.Content>{review.desc}</S.Content>
            <S.Footer>
              <S.FooterText>
                {review.footer.map((item, idx) => (
                  <S.FooterItem key={idx}>{item}</S.FooterItem>
                ))}
              </S.FooterText>
            </S.Footer>
          </S.ReviewCard>
        ))}
      </S.CarouselWrapper>
    </S.ReviewSection>
  )
}

export default Review
