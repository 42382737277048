import styled from 'styled-components'
import { onNotLarge } from '../../../styles/mediaQueries'

export const ReviewSection = styled.section`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f6f9;
  ${onNotLarge} {
    padding: 100px 0;
  }
`

export const Emoji = styled.h2`
  font-size: 40px;
`

export const Title = styled.h3`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  em {
    color: #3984f4;
  }
  ${onNotLarge} {
    font-size: 28px;
    margin-bottom: 10px;
  }
`

export const SubTitle = styled.p`
  font-size: 20px;
  color: #b1b0af;
  font-weight: 600;
  margin-bottom: 60px;
  ${onNotLarge} {
    font-size: 16px;
  }
`

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 20px;
  /* justify-content: center; */
  padding: 0 60px;
  width: 100%;
  ${onNotLarge} {
    gap: 16px;
    padding: 20px 0 20px 20px;
    justify-content: flex-start;
  }
`

export const ReviewCard = styled.div`
  min-width: 300px;
  background: white;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardTitle = styled.div`
  color: #3984f4;
  font-size: 14px;
  font-family: 'Pretendard';
  font-weight: 700;
  word-wrap: break-word;
`

export const CardSubtitle = styled.div`
  width: 276px;
  color: black;
  font-size: 20px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 28px;
  word-wrap: break-word;
`

export const Content = styled.div`
  width: 276px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FooterText = styled.div`
  display: flex;
  gap: 10px;
`

export const FooterItem = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: 'Pretendard';
  font-weight: 500;
  word-wrap: break-word;
`
